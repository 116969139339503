import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/login'; // 假设你的登录组件在这里
import Producer from '@/views/producer'; // 生产商页面组件
import Middleman from '@/views/middleman'; // 中间商页面组件
import Retailer from '@/views/retailer'; // 零售商页面组件
import Consumer from '@/views/consumer'; // 消费者页面组件
import ProducerHome from '@/views/producer/homePage'; // 生产商主页
import ProducerNewlyBuilt from '@/views/producer/newlyBuilt'; // 生产商新建页面
import MiddlemanHome from '@/views/middleman/homePage'; // 中间商主页
import MiddlemanNewlyBuilt from '@/views/middleman/newlyBuilt'; // 中间商新建页面
import RetailerHome from '@/views/retailer/homePage'; // 零售商主页
import RetailerNewlyBuilt from '@/views/retailer/newlyBuilt'; // 零售商新建页面

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/producer',
    component: Producer,
    redirect: '/producer/home', // 自动重定向到homePage
    children: [
      {
        path: 'home',
        name: 'ProducerHome',
        component: ProducerHome,
      },
      {
        path: 'newlyBuilt',
        name: 'ProducerNewlyBuilt',
        component: ProducerNewlyBuilt,
      },
    ],
  },
  {
    path: '/middleman',
    component: Middleman,
    redirect: '/middleman/home', // 自动重定向到homePage
    children: [
      {
        path: 'home',
        name: 'MiddlemanHome',
        component: MiddlemanHome,
      },
      {
        path: 'newlyBuilt',
        name: 'MiddlemanNewlyBuilt',
        component: MiddlemanNewlyBuilt,
      },
    ],
  },
  {
    path: '/retailer',
    component: Retailer,
    redirect: '/retailer/home', // 自动重定向到homePage
    children: [
      {
        path: 'home',
        name: 'RetailerHome',
        component: RetailerHome,
      },
      {
        path: 'newlyBuilt',
        name: 'RetailerNewlyBuilt',
        component: RetailerNewlyBuilt,
      },
    ],
  },
  {
    path: '/consumer',
    name: 'Consumer',
    component: Consumer,
  },
];

const router = new Router({
  mode: 'hash', // 使用 HTML5 历史模式
  routes,
});

export default router;
