<template>
  <div class="homePage">
    <div class="server">
      <div class="btn">
        <h2>服务器信息</h2>
        <el-row>
          <el-button
            icon="el-icon-refresh"
            circle
            @click="refreshData"
          ></el-button>
        </el-row>
      </div>
      <div class="computer">
        <table>
          <thead>
            <tr>
              <th>CPU信息</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>核心数: {{ cpu.cores }}个</td>
            </tr>
            <tr>
              <td>用户使用率: {{ cpu.user }}</td>
            </tr>
            <tr>
              <td>系统使用率: {{ cpu.system }}</td>
            </tr>
            <tr>
              <td>空闲率: {{ cpu.idle }}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>内存信息</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>总内存：{{ formatBytes(memory.total) }}</td>
            </tr>
            <tr>
              <td>已用内存: {{ formatBytes(memory.used) }}</td>
            </tr>
            <tr>
              <td>剩余内存: {{ formatBytes(memory.free) }}</td>
            </tr>
            <tr>
              <td>
                内存使用率: {{ memory.usage ? memory.usage + "%" : "N/A" }}
              </td>
            </tr>
          </tbody>
        </table>
        <div id="cpuChart" style="width: 200px; height: 200px"></div>
        <div id="memoryChart" style="width: 200px; height: 200px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";

export default {
  data() {
    return {
      cpu: {},
      memory: {}, // 初始化 memory 对象
      cpuChart: null, // 添加这个属性
      memoryChart: null, // 添加这个属性
    };
  },
  created() {
    this.fetchSystemInfo();
  },
  methods: {
    async fetchSystemInfo() {
      try {
        const response = await axios.get("http://www.aopg.cn/api/system-info");
        this.cpu = response.data.cpu;
        this.memory = response.data.memory;

        // 计算内存使用率并赋值
        if (this.memory.total) {
          this.memory.usage = (
            (this.memory.used / this.memory.total) *
            100
          ).toFixed(2);
        } else {
          this.memory.usage = 0; // 默认值
        }

        // 数据获取后初始化图表
        this.initEcharts();
      } catch (error) {
        console.error("获取服务器信息失败:", error);
      }
    },
    formatBytes(bytes) {
      // 检查传入的 bytes 是否是有效的数字
      if (typeof bytes !== "number" || isNaN(bytes)) {
        return "0 B"; // 默认值
      }

      const units = ["B", "KB", "MB", "GB", "TB"];
      let index = 0;
      while (bytes >= 1024 && index < units.length - 1) {
        bytes /= 1024;
        index++;
      }
      return `${bytes.toFixed(2)} ${units[index]}`;
    },
    initEcharts() {
      // 销毁已有的图表实例（如果存在）
      if (this.cpuChart) {
        this.cpuChart.dispose();
      }
      if (this.memoryChart) {
        this.memoryChart.dispose();
      }

      // 初始化图表实例
      this.cpuChart = echarts.init(document.getElementById("cpuChart"));
      this.memoryChart = echarts.init(document.getElementById("memoryChart"));

      // CPU图表
      const userUsage = parseFloat(this.cpu.user) || 0;
      const systemUsage = parseFloat(this.cpu.system) || 0;

      const cpuOption = {
        title: {
          text: `${(userUsage + systemUsage).toFixed(2)}%`, // 显示总使用率
          subtext: "CPU使用率", // 修改标题为“CPU使用率”
          left: "center",
          top: "center",
          textStyle: {
            fontSize: 15, // 修改为15
            fontWeight: "bold",
          },
          subtextStyle: {
            fontSize: 11, // 修改为11
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)", // 提示框格式
        },
        series: [
          {
            name: "CPU使用情况",
            type: "pie",
            data: [
              { value: userUsage + systemUsage, name: "使用率" }, // 只显示 CPU 使用率
              { value: parseFloat(this.cpu.idle) || 0, name: "空闲率" },
            ],
            radius: ["40%", "70%"],
            label: {
              show: false, // 隐藏图例上的标签
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      // 设置CPU图表选项
      this.cpuChart.setOption(cpuOption);

      // 内存图表
      const usedMemory = parseFloat(this.memory.used) || 0;
      const freeMemory = parseFloat(this.memory.free) || 0;

      const memoryOption = {
        title: {
          text: `${this.memory.usage}%`, // 显示内存使用率
          subtext: "内存使用率", // 修改标题为“内存使用率”
          left: "center",
          top: "center",
          textStyle: {
            fontSize: 15, // 修改为15
            fontWeight: "bold",
          },
          subtextStyle: {
            fontSize: 11, // 修改为11
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)", // 提示框格式
        },
        series: [
          {
            name: "内存使用情况",
            type: "pie",
            data: [
              { value: usedMemory, name: "已用内存" },
              { value: freeMemory, name: "剩余内存" },
            ],
            radius: ["40%", "70%"],
            label: {
              show: false, // 隐藏图例上的标签
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      // 设置内存图表选项
      this.memoryChart.setOption(memoryOption);
    },
    async refreshData() {
      await this.fetchSystemInfo(); // 刷新数据
    },
  },
};
</script>

<style scoped>
.homePage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}

h2 {
  width: 140px;
}

.server {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 80%;
  background-color: #d3dce6;
  padding: 20px 20px;
  border-radius: 10px;
}

.computer {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.btn {
  display: flex;
  justify-content: space-between;
}

table {
  margin: 0px 10px;
}

table tr,
table th,
table td {
  height: 40px;
  width: 200px;
  padding: 0px 10px;
}

table,
tr,
td,
th {
  border: 1px solid #b3c0d1;
  border-collapse: collapse;
  text-align: left;
  font-size: 16px;
}
</style>
