<template>
  <div class="login">
    <el-container style="height: 100vh">
      <el-header>
        <h1>食品溯源系统</h1>
      </el-header>
      <el-main>
        <div class="formBox">
          <el-form
            ref="form"
            :model="form"
            label-width="80px"
            :style="formStyle"
          >
            <el-alert
              v-if="showAlert"
              :title="title"
              type="error"
              show-icon
              :style="alertStyle"
              @click="turnOff"
            ></el-alert>
            <el-form-item label="用户地址">
              <el-input
                placeholder="请输入用户地址"
                v-model="form.address"
                clearable
                :style="inputStyle"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户密码">
              <el-input
                placeholder="请输入密码"
                v-model="form.password"
                show-password
                :style="inputStyle"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户类别">
              <el-select
                v-model="form.region"
                placeholder="请选择用户类别"
                :style="inputStyle"
              >
                <el-option label="生产商" value="producer"></el-option>
                <el-option label="中间商" value="middleman"></el-option>
                <el-option label="零售商" value="retailer"></el-option>
                <el-option label="消费者" value="consumer"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">登录</el-button>
              <el-button @click="reset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-main>
      <el-footer>备案号:赣ICP备2024035660号-2</el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "loginComponents",
  data() {
    return {
      form: {
        region: "",
        password: "",
        address: "",
      },
      inputStyle: {
        width: "80%",
      },
      alertStyle: {
        textAlign: "center",
        transition: "3s",
        opacity: "1",
        position: "absolute",
        top: "0px",
        left: "50%",
        zIndex: "100",
        width: "50%",
        transform: "translate(-50%, -0px)",
        marginTop: "10px",
      },
      formStyle: {
        position: "relative",
        width: "30%",
        backgroundColor: "#b3c0d1",
        padding: "20px",
        borderRadius: "5px",
        paddingTop: "66px",
      },
      title: "",
      showAlert: false,
    };
  },
  methods: {
    reset() {
      this.form.password = "";
      this.form.address = "";
      this.form.region = "";
      this.showAlert = false; // 重置时隐藏警告
    },
    onSubmit() {
      if (
        this.form.address === "" ||
        this.form.password === "" ||
        this.form.region === ""
      ) {
        this.title = "三者不可为空";
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
        return;
      }

      // 假设验证条件
      if (
        (this.form.address === "1" &&
          this.form.password === "1" &&
          this.form.region === "producer") ||
        (this.form.address === "2" &&
          this.form.password === "2" &&
          this.form.region === "middleman") ||
        (this.form.address === "3" &&
          this.form.password === "3" &&
          this.form.region === "retailer") ||
        (this.form.address === "4" &&
          this.form.password === "4" &&
          this.form.region === "consumer")
      ) {
        // 生成随机 token
        const token = Math.random().toString(36).substring(2, 15); // 随机生成 token
        localStorage.setItem("token", token); // 保存到 localStorage

        // 设置定时器，30分钟后删除 token
        setTimeout(() => {
          localStorage.removeItem("token");
        }, 1800000);

        // 跳转页面
        this.$router.replace(`/${this.form.region}`);
        return;
      } else {
        this.title = "请检查账号密码与用户类别";
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
        return;
      }
    },
    turnOff() {
      this.showAlert = false;
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-header h1 {
  text-align: left;
}

.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  flex: 1; /* 使 el-main 占据剩余空间 */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.formBox {
  width: 100%;
  display: flex;
  justify-content: right;
}
</style>
