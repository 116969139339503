<template>
  <div class="consumer">
    <el-container style="height: 100vh">
      <el-header>
        <h1>食品溯源系统</h1>
        <el-row>
          <el-button type="info" @click="loginout">退出登录</el-button>
        </el-row>
        <el-dialog
          title="温馨提示"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose"
        >
          <span>请问确定退出登录吗？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="loginout2">确定</el-button>
          </span>
        </el-dialog>
      </el-header>
      <el-main>
        <div class="block">
          <el-form :inline="true" class="demo-form-inline" :style="elFormStyle">
            <el-form-item label="溯源码">
              <el-input
                placeholder="请输入溯源码"
                v-model="traceCode"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSubmit">查询</el-button>
            </el-form-item>
          </el-form>

          <!-- 时间轴展示 -->
          <el-timeline v-if="showTimeline">
            <el-timeline-item
              v-for="(info, index) in timelineInfo"
              :key="index"
              :timestamp="info.times"
              placement="top"
            >
              <el-card>
                <h4>生产商：{{ info.trader }}</h4>
                <p>溯源码: {{ info.code }}</p>
                <p>食品名称: {{ info.name }}</p>
                <p>新鲜度: {{ info.freshness }}</p>
                <p>经手时间: {{ info.times }}</p>
                <p>经手地点: {{ info.address }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-main>
      <el-footer>测试使用</el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "consumerComponents",
  data() {
    return {
      dialogVisible: false,
      traceCode: "", // 用户输入的溯源码
      showTimeline: false, // 控制是否显示时间轴
      timelineInfo: [], // 存储需要渲染的商户信息
      information: JSON.parse(localStorage.getItem("information")) || [],
      informationMiddleman:
        JSON.parse(localStorage.getItem("informationMiddleman")) || [],
      informationRetailer:
        JSON.parse(localStorage.getItem("informationRetailer")) || [],
      elFormStyle: {
        position: "absolute",
        top: "20px",
        left: "50%",
        transform: "translate(-50%, -20px)",
        marginTop: "20px",
      },
    };
  },
  methods: {
    handleSubmit() {
      // 检查溯源码是否存在于三方的记录中
      const producerInfo = this.information.find(
        (item) => item.code === this.traceCode
      );
      const middlemanInfo = this.informationMiddleman.find(
        (item) => item.code === this.traceCode
      );
      const retailerInfo = this.informationRetailer.find(
        (item) => item.code === this.traceCode
      );

      if (producerInfo && middlemanInfo && retailerInfo) {
        // 如果三者都存在，则将信息存入时间轴展示
        this.timelineInfo = [
          this.mapFreshness(producerInfo),
          this.mapFreshness(middlemanInfo),
          this.mapFreshness(retailerInfo),
        ];
        this.showTimeline = true; // 显示时间轴
      } else {
        this.showTimeline = false; // 如果溯源码不匹配，则隐藏时间轴
        this.timelineInfo = [];
        this.$message.error("溯源码不存在或不匹配");
      }
    },
    // freshness 映射函数
    mapFreshness(info) {
      const freshnessMap = {
        0: "新鲜",
        1: "中等",
        2: "过期",
      };
      return {
        ...info,
        freshness: freshnessMap[info.freshness] || "未知", // 转换 freshness 值
      };
    },
    loginout() {
      this.dialogVisible = true;
    },
    loginout2() {
      localStorage.removeItem("token"); // 清除 token
      this.$router.replace("/"); // 跳转到首页
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.consumer {
  width: 100%;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}

.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.block {
  width: 100%;
}
</style>
