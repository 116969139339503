<template>
  <div class="newlyBuilt">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="新鲜度">
        <el-input
          v-model="information.freshness"
          placeholder="请输入新鲜度"
          @blur="validateFreshness"
        ></el-input>
        <span v-if="freshnessError" style="color: red">{{
          freshnessError
        }}</span>
      </el-form-item>
      <el-form-item label="食物名">
        <el-input
          v-model="information.name"
          placeholder="请输入食物名"
          @blur="validateName"
        ></el-input>
        <span v-if="nameError" style="color: red">{{ nameError }}</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="increase">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="filteredTableData" border style="width: 100%" height="80%">
      <el-table-column prop="code" label="溯源码"></el-table-column>
      <el-table-column prop="freshness" label="新鲜度"></el-table-column>
      <el-table-column prop="name" label="食品名"></el-table-column>
      <el-table-column prop="times" label="经手时间"></el-table-column>
      <el-table-column prop="address" label="经手地点"></el-table-column>
      <el-table-column prop="trader" label="经手商户"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "NewlyBuilt",
  data() {
    return {
      information: {
        code: "",
        freshness: "",
        name: "",
        times: "",
        address: "北京",
        trader: "producer1",
      },
      freshnessError: "",
      nameError: "",
      tableData: [],
      search: "",
      formInline: {},
    };
  },
  computed: {
    filteredTableData() {
      return this.tableData.filter((data) => {
        return (
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  mounted() {
    this.loadInformationFromLocalStorage();
    window.addEventListener("beforeunload", this.clearLocalStorage);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.clearLocalStorage);
  },
  methods: {
    generateUniqueCode() {
      let code;
      const existingCodes = JSON.parse(localStorage.getItem("codes")) || [];
      do {
        code = String(Math.floor(Math.random() * 100000000)).padStart(8, "0");
      } while (existingCodes.includes(code));
      existingCodes.push(code);
      localStorage.setItem("codes", JSON.stringify(existingCodes));
      return code;
    },
    validateFreshness() {
      const freshness = Number(this.information.freshness);
      if (isNaN(freshness) || freshness < 0 || freshness > 2) {
        this.freshnessError = "新鲜度必须是0-2之间的数字";
      } else {
        this.freshnessError = "";
      }
    },
    validateName() {
      const namePattern = /^[a-zA-Z\u4e00-\u9fa5]+$/; // 仅允许英文和汉字
      if (this.information.name.trim() === "") {
        this.nameError = "食物名不能为空";
      } else if (!namePattern.test(this.information.name)) {
        this.nameError = "食物名必须是英文或汉字";
      } else {
        this.nameError = "";
      }
    },
    increase() {
      this.validateFreshness();
      this.validateName();
      if (!this.freshnessError && !this.nameError) {
        this.information.code = this.generateUniqueCode();
        this.information.times = new Date().toLocaleString();
        this.tableData.push({ ...this.information });
        localStorage.setItem("information", JSON.stringify(this.tableData));
      }
    },
    loadInformationFromLocalStorage() {
      const storedData = localStorage.getItem("information");
      if (storedData) {
        this.tableData = JSON.parse(storedData);
      }
    },
    clearLocalStorage() {
      localStorage.removeItem("information");
      localStorage.removeItem("codes");
    },
  },
};
</script>

<style scoped>
.newlyBuilt {
  height: 100%;
}
</style>
