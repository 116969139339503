<template>
  <div class="middlemanViews">
    <el-container style="height: 100vh">
      <el-header>
        <h2>中间商管理系统</h2>
        <el-row>
          <el-button type="info" @click="loginout">退出登录</el-button>
        </el-row>
        <el-dialog
          title="温馨提示"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose"
        >
          <span>请问确定退出登录吗？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="loginout2">确定</el-button>
          </span>
        </el-dialog>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-row class="tac" :style="elRow">
            <el-col :span="12">
              <el-menu class="el-menu-vertical-demo">
                <el-menu-item
                  index="2"
                  :class="{ 'is-active': $route.path === '/middleman/home' }"
                  @click="toHomePage"
                >
                  <i class="el-icon-menu"></i>
                  <span>中间商首页</span>
                </el-menu-item>
                <el-menu-item
                  index="4"
                  :class="{
                    'is-active': $route.path === '/middleman/newlyBuilt',
                  }"
                  @click="toNewlyBuilt"
                >
                  <i class="el-icon-setting"></i>
                  <span>新建溯源信息</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "middlemanViews",
  data() {
    return {
      dialogVisible: false,
      elRow: {
        backgroundColor: "#d3dce6",
      },
      checkTokenInterval: null, // 定时器 ID
    };
  },
  mounted() {
    this.checkToken(); // 初次检查 token
    this.checkTokenInterval = setInterval(this.checkToken, 10 * 1000); // 每10秒检查一次
    window.addEventListener("beforeunload", this.clearLocalStorage);
  },
  beforeDestroy() {
    clearInterval(this.checkTokenInterval); // 清除定时器
    window.removeEventListener("beforeunload", this.clearLocalStorage);
  },
  methods: {
    checkToken() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.$router.replace("/"); // 跳转到首页
      }
    },
    loginout() {
      this.dialogVisible = true;
    },
    loginout2() {
      localStorage.removeItem("token"); // 清除 token
      this.$router.replace("/"); // 跳转到首页
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    toHomePage() {
      if (this.$route.path !== "/middleman/home") {
        this.$router.replace("/middleman/home");
      }
    },
    // 跳转到新建溯源信息页面
    toNewlyBuilt() {
      if (this.$route.path !== "/middleman/newlyBuilt") {
        this.$router.replace("/middleman/newlyBuilt");
      }
    },
    clearLocalStorage() {
      localStorage.removeItem("information");
      localStorage.removeItem("informationMiddleman");
      localStorage.removeItem("codes");
    },
  },
};
</script>

<style scoped>
.middlemanViews {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.el-col {
  width: 100%;
}

.el-menu {
  background-color: #d3dce6;
}
</style>
