<template>
  <div class="newlyBuilt">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="溯源码">
        <el-input
          v-model="informationMiddleman.code"
          placeholder="请输入溯源码"
          @blur="validateFreshness"
        ></el-input>
      </el-form-item>
      <el-form-item label="新鲜度">
        <el-input
          v-model="informationMiddleman.freshness"
          placeholder="请输入新鲜度"
          @blur="validateFreshness"
        ></el-input>
        <span v-if="freshnessError" style="color: red">{{
          freshnessError
        }}</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="increase">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="filteredTableData" border style="width: 100%" height="80%">
      <el-table-column prop="code" label="溯源码"></el-table-column>
      <el-table-column prop="freshness" label="新鲜度"></el-table-column>
      <el-table-column prop="name" label="食品名"></el-table-column>
      <el-table-column prop="times" label="经手时间"></el-table-column>
      <el-table-column prop="address" label="经手地点"></el-table-column>
      <el-table-column prop="trader" label="经手商户"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "NewlyBuilt",
  data() {
    return {
      informationMiddleman: {
        code: "",
        freshness: "",
        name: "",
        times: "",
        address: "上海",
        trader: "middleman1",
      },
      freshnessError: "",
      duplicateCodeError: "",
      tableData: [],
      search: "",
      formInline: {},
    };
  },
  computed: {
    filteredTableData() {
      return this.tableData.filter((data) => {
        return (
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  mounted() {
    this.loadInformationFromLocalStorage();
    window.addEventListener("beforeunload", this.clearLocalStorage);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.clearLocalStorage);
  },
  methods: {
    validateFreshness() {
      const freshness = Number(this.informationMiddleman.freshness);
      // 确保新鲜度在 0-2 之间
      if (isNaN(freshness) || freshness < 0 || freshness > 2) {
        this.freshnessError = "新鲜度必须是0-2之间的数字";
      } else {
        this.freshnessError = "";
      }
    },
    increase() {
      this.validateFreshness();

      // 检查溯源码是否在 LocalStorage 的 information 中
      const storedInformation =
        JSON.parse(localStorage.getItem("information")) || [];
      const codeExistsInInformation = storedInformation.some(
        (item) => item.code === this.informationMiddleman.code
      );

      // 检查 informationMiddleman 中的 code 是否重复
      const storedMiddlemanData =
        JSON.parse(localStorage.getItem("informationMiddleman")) || [];
      const codeExistsInMiddleman = storedMiddlemanData.some(
        (item) => item.code === this.informationMiddleman.code
      );

      // 仅当新鲜度没有错误，溯源码在 information 中存在，并且在 middleman 中不重复时，添加数据
      if (!this.freshnessError && codeExistsInInformation) {
        if (codeExistsInMiddleman) {
          this.duplicateCodeError = "溯源码重复，请使用其他溯源码！";
        } else {
          this.duplicateCodeError = ""; // 清除错误提示

          // 找到对应的 information 中的 name
          const correspondingInfo = storedInformation.find(
            (item) => item.code === this.informationMiddleman.code
          );
          if (correspondingInfo) {
            this.informationMiddleman.name = correspondingInfo.name; // 赋值
          }

          this.informationMiddleman.times = new Date().toLocaleString();
          // 创建一个新的信息对象
          const newInformation = { ...this.informationMiddleman };
          // 将新的信息对象添加到表格数据中
          this.tableData.push(newInformation);
          // 更新 LocalStorage
          const newMiddlemanData = storedMiddlemanData.concat(newInformation);
          localStorage.setItem(
            "informationMiddleman",
            JSON.stringify(newMiddlemanData)
          );
        }
      } else if (!codeExistsInInformation) {
        alert("溯源码不存在，请输入正确的溯源码！");
      }
    },
    loadInformationFromLocalStorage() {
      const storedData = localStorage.getItem("informationMiddleman");
      if (storedData) {
        this.tableData = JSON.parse(storedData);
      }
    },
    clearLocalStorage() {
      localStorage.removeItem("informationMiddleman");
      localStorage.removeItem("information");
    },
  },
};
</script>

<style scoped>
.newlyBuilt {
  height: 100%;
}
</style>
