<template>
  <div class="newlyBuilt">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="溯源码">
        <el-input
          v-model="informationRetailer.code"
          placeholder="请输入溯源码"
          @blur="validateFreshness"
        ></el-input>
      </el-form-item>
      <el-form-item label="新鲜度">
        <el-input
          v-model="informationRetailer.freshness"
          placeholder="请输入新鲜度"
          @blur="validateFreshness"
        ></el-input>
        <span v-if="freshnessError" style="color: red">{{
          freshnessError
        }}</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="increase">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="filteredTableData" border style="width: 100%" height="80%">
      <el-table-column prop="code" label="溯源码"></el-table-column>
      <el-table-column prop="freshness" label="新鲜度"></el-table-column>
      <el-table-column prop="name" label="食品名"></el-table-column>
      <el-table-column prop="times" label="经手时间"></el-table-column>
      <el-table-column prop="address" label="经手地点"></el-table-column>
      <el-table-column prop="trader" label="经手商户"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "NewlyBuilt",
  data() {
    return {
      informationRetailer: {
        code: "",
        freshness: "",
        name: "",
        times: "",
        address: "广州",
        trader: "Retailer1",
      },
      freshnessError: "",
      duplicateCodeError: "",
      codeNotInMiddlemanError: "",
      tableData: [],
      search: "",
      formInline: {},
    };
  },
  computed: {
    filteredTableData() {
      return this.tableData.filter((data) => {
        return (
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  mounted() {
    this.loadInformationFromLocalStorage();
    window.addEventListener("beforeunload", this.clearLocalStorage);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.clearLocalStorage);
  },
  methods: {
    validateFreshness() {
      const freshness = Number(this.informationRetailer.freshness);
      if (isNaN(freshness) || freshness < 0 || freshness > 2) {
        this.freshnessError = "新鲜度必须是0-2之间的数字";
      } else {
        this.freshnessError = "";
      }
    },
    increase() {
      this.validateFreshness();

      const storedInformation =
        JSON.parse(localStorage.getItem("information")) || [];
      const codeExistsInInformation = storedInformation.some(
        (item) => item.code === this.informationRetailer.code
      );

      const storedRetailerData =
        JSON.parse(localStorage.getItem("informationRetailer")) || [];
      const codeExistsInRetailer = storedRetailerData.some(
        (item) => item.code === this.informationRetailer.code
      );

      const storedMiddlemanData =
        JSON.parse(localStorage.getItem("informationMiddleman")) || [];
      const codeExistsInMiddleman = storedMiddlemanData.some(
        (item) => item.code === this.informationRetailer.code
      );

      if (!this.freshnessError && codeExistsInInformation) {
        if (!codeExistsInMiddleman) {
          this.codeNotInMiddlemanError =
            "溯源码在信息中不存在，请输入正确的溯源码！";
        } else {
          this.codeNotInMiddlemanError = ""; // 清除错误提示

          if (codeExistsInRetailer) {
            this.duplicateCodeError = "溯源码重复，请使用其他溯源码！";
          } else {
            this.duplicateCodeError = ""; // 清除错误提示

            const correspondingInfo = storedInformation.find(
              (item) => item.code === this.informationRetailer.code
            );
            if (correspondingInfo) {
              this.informationRetailer.name = correspondingInfo.name; // 赋值
            }

            this.informationRetailer.times = new Date().toLocaleString();
            const newInformation = { ...this.informationRetailer };
            this.tableData.push(newInformation);
            const newRetailerData = storedRetailerData.concat(newInformation);
            localStorage.setItem(
              "informationRetailer",
              JSON.stringify(newRetailerData)
            );
          }
        }
      } else if (!codeExistsInInformation) {
        alert("溯源码不存在，请输入正确的溯源码！");
      }
    },
    loadInformationFromLocalStorage() {
      const storedData = localStorage.getItem("informationRetailer");
      if (storedData) {
        this.tableData = JSON.parse(storedData);
      }
    },
    clearLocalStorage() {
      localStorage.removeItem("informationRetailer");
      localStorage.removeItem("information");
    },
  },
};
</script>

<style scoped>
.newlyBuilt {
  height: 100%;
}
</style>
