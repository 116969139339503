import { render, staticRenderFns } from "./newlyBuilt.vue?vue&type=template&id=5c8dcdac&scoped=true"
import script from "./newlyBuilt.vue?vue&type=script&lang=js"
export * from "./newlyBuilt.vue?vue&type=script&lang=js"
import style0 from "./newlyBuilt.vue?vue&type=style&index=0&id=5c8dcdac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c8dcdac",
  null
  
)

export default component.exports